<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button
                label="Novo Tipo de Sub Classe"
                icon="pi pi-plus"
                class="p-button-success mr-2"
                @click="openNew"
              />
              <Button
                label="Apagar"
                icon="pi pi-trash"
                class="p-button-danger"
                @click="confirmDeleteSelected"
                :disabled="
                  !selectUnidadesTipoSubclasse || !selectUnidadesTipoSubclasse.length
                "
              />
            </div>
          </template>

          <template v-slot:end>
            <!-- <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />-->
            <Button
              label="Exportar"
              icon="pi pi-upload"
              class="p-button-help"
              @click="exportCSV($event)"
            />
          </template>
        </Toolbar>

        <!-- Tabela de Tipos de Subclasse -->
        <DataTable
          ref="dt"
          :value="tiposSubclasses"
          v-model:selection="selectUnidadesTipoSubclasse"
          dataKey="id"
          :paginator="true"
          :rows="50"
          :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
          responsiveLayout="scroll"
        >
          <template #header>
            <div
              class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
            >
              <h5 class="m-0">Gereneciar Tipos de Sub Classe</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Buscar" />
              </span>
            </div>
          </template>

          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
          <Column
            field="id"
            header="ID"
            :sortable="true"
            headerStyle="width:14%; min-width:5rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Id</span>
              {{ slotProps.data.id }}
            </template>
          </Column>

          <Column
            field="descricao"
            header="Descricao"
            :sortable="true"
            headerStyle="width:30%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Descrição</span>
              {{ slotProps.data.descricao }}
            </template>
          </Column>

          <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success mr-2"
                @click="editProduct(slotProps.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning mt-2"
                @click="apagarTipoSubclasseModal(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>

        <!-- Modal de edição de Tipo de Sub Classe -->
        <Dialog
          v-model:visible="productDialog"
          :style="{ width: '450px' }"
          header="Editar Tipo de Sub Classe"
          :modal="true"
          class="p-fluid"
        >
          <div class="field">
            <label for="name">Descrição</label>
            <InputText
              id="bome"
              v-model.trim="tipoSubclasse.descricao"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !tipoSubclasse.descricao }"
            />
            <small class="p-invalid" v-if="submitted && !tipoSubclasse.descricao"
              >Descrição é obrigatória.</small
            >
          </div>

          <template #footer>
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-secondary p-button-sm"
              @click="hideDialog"
            />
            <Button
              label="Salvar"
              icon="pi pi-check"
              class="p-button-success p-button-sm"
              @click="salvarTipoSubclasse"
            />
          </template>
        </Dialog>

        <!-- Modal deletar Tipo de Sub Classe  -->
        <Dialog
          v-model:visible="deleteProductDialog"
          :style="{ width: '450px' }"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="tipoSubclasse"
              >Certeza que deseja apagar este item: <b>{{ tipoSubclasse.descricao }}</b
              >?</span
            >
          </div>
          <template #footer class="align-items-center">
            <Button
              label="Não"
              icon="pi pi-times"
              class="p-button-secondary p-button-sm"
              @click="deleteProductDialog = false"
            />
            <Button
              label="Sim"
              icon="pi pi-check"
              class="p-button-danger p-button-sm"
              @click="apagarTipoSubclasse"
            />
          </template>
        </Dialog>

        <!-- Modal deletar vários selecionados -->
        <Dialog
          v-model:visible="deleteProductsDialog"
          :style="{ width: '450px' }"
          header=""
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="tipoSubclasse">Apagar Tipos de Subclasses selecionadas?</span>
          </div>
          <template #footer>
            <Button
              label="Não"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductsDialog = false"
            />
            <Button
              label="Sim"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteSelectedProducts"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";

export default {
  data() {
    return {
      tiposSubclasses: [],
      tipoSubclasse: {},
      selectUnidadesTipoSubclasse: null,
      selectedProducts: null,
      productDialog: false,
      deleteProductDialog: false,
      filters: {},
      submitted: false,
      deleteProductsDialog: false,
    };
  },
  created() {
    this.initFilters();
  },
  mounted() {
    this.listarTiposSubclasse();
  },

  methods: {
    // Listar tipos de subclasse do end-point
    listarTiposSubclasse() {
      this.axios
        .post("/unidades/dados/tipos-sub-classe/listar")
        .then((response) => {
          this.tiposSubclasses = response.data.dados;
        })
        .catch(() => {
          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },

    openNew() {
      this.tipoSubclasse = {};
      this.submitted = false;
      this.productDialog = true;
    },

    editProduct(tipoSubclasse) {
      this.productDialog = true;
      this.tipoSubclasse = { ...tipoSubclasse };
    },

    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },

    // Criar / Editar tipos de subclasses
    salvarTipoSubclasse() {
      this.submitted = true;

      if (this.tipoSubclasse.id) {
        // Atualizar um Tipo de Sub Classe
        this.axios
          .post("/unidades/dados/tipos-sub-classe/editar", this.tipoSubclasse)
          .then(() => {
            this.productDialog = false;
            this.tipoSubclasse = {};
            this.listarTiposSubclasse();
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      } else {
        // Cadastrar novo tipo de subclasse
        this.axios
          .post("/unidades/dados/tipos-sub-classe/adicionar", this.tipoSubclasse)
          .then(() => {
            this.productDialog = false;
            this.tipoSubclasse = {};
            this.listarTiposSubclasse();
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      }
    },

    //Apaga um tipo de sublasse
    apagarTipoSubclasse() {
      this.axios
        .post("/unidades/dados/tipos-sub-classe/apagar", this.tipoSubclasse)
        .then(() => {
          this.deleteProductDialog = false;
          this.tipoSubclasse = {};
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Tipo de Sub Classe apagada com sucesso!",
            life: 3000,
          });
          this.listarTiposSubclasse();
        })
        .catch(() => {
          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },

    //Apaga o Modal que abre ao selecionar que deseja apagar um tipo de sublasse
    apagarTipoSubclasseModal(tipoSubclasse) {
      this.tipoSubclasse = tipoSubclasse;
      this.deleteProductDialog = true;
    },

    // Botao de excluir selecionados
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },

    // Faz o delete de vários itens selecionados no datatable
    deleteSelectedProducts() {
      this.tiposSubclasses = this.tiposSubclasses.filter(
        (val) => !this.selectUnidadesTipoSubclasse.includes(val)
      );

      let obj = this.selectUnidadesTipoSubclasse;
      Object.keys(obj).forEach((key) => {
        this.axios
          .post("/unidades/dados/tipos-sub-classe/apagar", obj[key])
          .then(() => {
            this.listarUsuario();
            this.deleteProductDialog = false;
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Tipos de sublcasses apagadas com sucesso!",
              life: 3000,
            });
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      });

      this.deleteProductsDialog = false;
      this.selectUnidadesTipoSubclasse = null;
      this.$toast.add({
        severity: "success",
        summary: "Sucesso",
        detail: "Products Deleted",
        life: 3000,
      });
    },

    exportCSV() {
      this.$refs.dt.exportCSV();
    },

    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>
